import { makePost } from 'services';

const LogType = {
  Information: 0,
  Warning: 1,
  Error: 2,
};

export const postErrorLog = (message: string, stacktrace: string) => postLog({ message, stacktrace, logType: LogType.Error });

export const postWarningLog = (message: string) => postLog({ message, logType: LogType.Warning });

export const postInformationLog = (message: string) => postLog({ message, logType: LogType.Information });

const postLog = (params: { message: string, logType: number, stacktrace?: string }) =>
  makePost('log', { app: 'DriveX SmartScan', ...params });
