import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useForceUpdate } from 'hooks';

type PortalProps = {
  elementNode?: string;
  children: React.ReactNode;
};

export function Portal(props: PortalProps) {
  const portalNode = useRef<HTMLElement | null>(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const fullscreenNodeRef = document.querySelector('.smartscan-fullscreen');

    if (fullscreenNodeRef) {
      portalNode.current = document.createElement('div');
      fullscreenNodeRef.appendChild(portalNode.current);
      forceUpdate();
    }

    return () => {
      if (fullscreenNodeRef && portalNode.current) {
        fullscreenNodeRef.removeChild(portalNode.current);
      }
    };
  }, [forceUpdate]);
  return portalNode.current && createPortal(props.children, portalNode.current);
}

Portal.defaultProps = {
  elementNode: 'smartscanportal',
};
