/* eslint-disable no-unused-vars */
import React from 'react';
import { ActionType } from 'typesafe-actions';

import { GENERIC_STATE_KEYS, IGenericState } from '../root';
import { TRANSLATIONS_VALUES_KEYS } from '../internationalization/internationalization';
import {
  Vin, Keys, Interior, Exterior, Odometer, Additional, Windshield,
  AxisCar, Motorcycle, DataSticker, Truck, Wheels, Bus, Trailer, Custom
} from 'assets';

export enum ImageTypeKeys {
  Exterior = 1,
  Interior = 2,
  Vin = 3,
  Windshield = 4,
  Additional = 5,
  Odometer = 6,
  Keys = 7,
  DataSticker = 8,
  Wheels = 9,
  Custom = 10
}

export type ImageTypeNumericKey =
  | ImageTypeKeys.Exterior
  | ImageTypeKeys.Interior
  | ImageTypeKeys.Vin
  | ImageTypeKeys.Windshield
  | ImageTypeKeys.Additional
  | ImageTypeKeys.Odometer
  | ImageTypeKeys.Keys
  | ImageTypeKeys.DataSticker
  | ImageTypeKeys.Wheels
  | ImageTypeKeys.Custom;

export enum VehicleTypeKeys {
  Car = 1,
  Motorcycle = 2,
  Truck = 3,
  Bus = 7,
  Trailer = 4
}

export type VehicleTypeKey = VehicleTypeKeys.Car
    | VehicleTypeKeys.Motorcycle
    | VehicleTypeKeys.Truck
    | VehicleTypeKeys.Bus
    | VehicleTypeKeys.Trailer;

export type ImageTypeStringKey = 'vin'
    | 'exterior'
    | 'interior'
    | 'windshield'
    | 'additional'
    | 'odometer'
    | 'keys'
    | 'dataSticker'
    | 'wheels'
    | 'custom';

export type ImageTypes = {
  [key in ImageTypeNumericKey]: { name: string; icon: React.FC<any> };
};

export type VehicleType = {
  [key in VehicleTypeKey]: { name: string; icon: React.FC<any> };
};

export interface IWorkflow {
  id: string;
  name: string;
  imageTypeSettings: ImageTypeSettings[];
  imageTypes: ImageTypeNumericKey[];
  imageSubTypes: number[];
  reportingEmail: string;
  vehicleType: VehicleTypeKey;
}

export interface ImageTypeSettings {
  imageType: number;
  enabled: boolean;
  order: number;
  enabledVerificationErrors?: (EnabledVerificationErrors | null)[] | null;
  enabledImageSubTypes?: number[] | null;
  smartScanCommentSettings: ISmartScanCommentSettings;
  imageSubTypes: SubTypeConfiguration[];
  manualDamageLabellingEnabled: boolean;
  customImageTypeId: string | null;
  optional: boolean;
}

export interface SubTypeConfiguration {
  imageSubType: number,
  instructions: InstructionTypeKeys[],
  optional: boolean
}

export enum InstructionTypeKeys {
  FirstWindshield = 0,
  SecondWindshield = 1,
  DoorJamb = 5,
  UnderDriverSeat = 10,
  PassengerDoorAndSeat = 15,
  EngineRoom = 20,
  PassengerSide = 25
}

export interface ISmartScanCommentSettings {
  enabled: boolean;
  automaticPopup: boolean;
}

export interface EnabledVerificationErrors {
  error: number;
  errorType: number;
}

export interface IWorkflowsState extends IGenericState {
  selectedWorkflow: IWorkflow;
}

export type TWorkflowsActions = ActionType<typeof import('./workflows.actions')>;

const WORKFLOWS_NAME = 'workflows';

const WORKFLOWS_STATE_KEYS = {
  ...GENERIC_STATE_KEYS,
  selectedWorkflow: 'selectedWorkflow',
};

const { vehicle_scan } = TRANSLATIONS_VALUES_KEYS;

const IMAGE_TYPES: ImageTypes = {
  [ImageTypeKeys.Exterior]: { name: vehicle_scan.process_exterior_step, icon: Exterior },
  [ImageTypeKeys.Interior]: { name: vehicle_scan.process_interior_step, icon: Interior },
  [ImageTypeKeys.Vin]: { name: vehicle_scan.process_vin_step, icon: Vin },
  [ImageTypeKeys.Windshield]: { name: vehicle_scan.process_windshield_step, icon: Windshield },
  [ImageTypeKeys.Additional]: { name: vehicle_scan.process_additional_step, icon: Additional },
  [ImageTypeKeys.Odometer]: { name: vehicle_scan.process_odometer_step, icon: Odometer },
  [ImageTypeKeys.Keys]: { name: vehicle_scan.process_keys_step, icon: Keys },
  [ImageTypeKeys.DataSticker]: { name: vehicle_scan.process_data_sticker_step, icon: DataSticker },
  [ImageTypeKeys.Wheels]: { name: vehicle_scan.process_wheels_step, icon: Wheels },
  [ImageTypeKeys.Custom]: { name: '', icon: Custom }
};

const VEHICLE_TYPES: VehicleType = {
  [VehicleTypeKeys.Car]: { name: 'Car', icon: AxisCar },
  [VehicleTypeKeys.Motorcycle]: { name: 'Motorcycle', icon: Motorcycle },
  [VehicleTypeKeys.Truck]: { name: 'Truck', icon: Truck },
  [VehicleTypeKeys.Bus]: { name: 'Bus', icon: Bus },
  [VehicleTypeKeys.Trailer]: { name: 'Trailer', icon: Trailer }
};

const VIN_SUB_TYPES = {
  FIRST_VIN: 30,
  INSPECTION_STICKER: 31,
  SECOND_VIN: 32
};

const EXTERIOR_SUB_TYPES = {
  FRONT: 1,
  FRONT_LEFT: 2,
  LEFT: 3,
  BACK_LEFT: 4,
  BACK: 5,
  BACK_RIGHT: 6,
  RIGHT: 7,
  FRONT_RIGHT: 8,
};

const INTERIOR_SUB_TYPES = {
  DASHBOARD: 10,
  TRUNK: 11,
  FRONT_LEFT_SEAT: 12,
  BACK_LEFT_SEAT: 13,
  FRONT_RIGHT_SEAT: 14,
  BACK_RIGHT_SEAT: 15
};

const WINDSHIELD_SUB_TYPES = {
  FROM_OUTSIDE: 20,
  FROM_INSIDE: 21,
  FACTORY_LABEL: 22
};

const ADDITIONAL_SUB_TYPES = {
  FIRST_CLOSE_UP: 40,
  SECOND_CLOSE_UP: 41,
  FIRST: 49,
};

const KEYS_SUB_TYPES = {
  FIRST: 51,
};

const ODOMETER_SUB_TYPES = {
  FIRST: 61,
};

const DATA_STICKER_SUB_TYPES = {
  FIRST: 71
};

const CUSTOM_SUB_TYPES = {
  FIRST: 81
};

const WHEELS_SUB_TYPES = {
  FRONT_LEFT: 2,
  BACK_LEFT: 4,
  BACK_RIGHT: 6,
  FRONT_RIGHT: 8
};

const IMAGE_VERIFICATION_ERROR_TYPE = {
  BLOCKER: 1,
  WARNING: 2,
};

const MANUAL_MARKING_IMAGE_TYPES = [
  ImageTypeKeys.Exterior,
  ImageTypeKeys.Windshield,
  ImageTypeKeys.Custom
];

export {
  WORKFLOWS_NAME,
  WORKFLOWS_STATE_KEYS,
  IMAGE_TYPES,
  VEHICLE_TYPES,
  VIN_SUB_TYPES,
  EXTERIOR_SUB_TYPES,
  INTERIOR_SUB_TYPES,
  WINDSHIELD_SUB_TYPES,
  ADDITIONAL_SUB_TYPES,
  KEYS_SUB_TYPES,
  ODOMETER_SUB_TYPES,
  DATA_STICKER_SUB_TYPES,
  IMAGE_VERIFICATION_ERROR_TYPE,
  MANUAL_MARKING_IMAGE_TYPES,
  WHEELS_SUB_TYPES,
  CUSTOM_SUB_TYPES
};
