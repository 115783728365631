import React, {
// useEffect
} from 'react';
import { useSelector,
  // useDispatch
} from 'react-redux';
import { Translate } from 'react-localize-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useTheme } from 'hooks';
import { SvgWrapper } from 'assets';
import { InstructionsSubDomains } from 'routes';
import { AccessibleIcon } from 'components/partials';
import { usePhotoSeriesStore } from '../../store/root.hook';
import {
  PhotoSeries,
  // WindshieldRepairReplaceDecision
} from 'redux/photoseries/photoseries';
import {
  getPhotoSeries,
  getInProgressDamageCapture,
  getAdditionalImagesPerDamage,
  getDamageCaptureProgress,
  // selectSelectedWorkflow
} from 'redux/root.selectors';
import { ReactComponent as IconForwardArrow } from 'assets/svgs/forward-arrow.svg';
import { ActionColumn } from '../../containers/action-column/action-column.component';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { Additional } from 'assets/components/instructions';
import { ImageTypeKeys, ImageTypeNumericKey, IMAGE_TYPES, VehicleTypeKeys,
  // ImageTypeSettings, ADDITIONAL_SUB_TYPES
} from 'redux/workflows/workflows';
import { ColumnLeft } from './containers';
import { CameraMode } from '../../store/root';
import { useNativeCameraUpload } from '../../hooks/use-native-camera-upload';
import { BikeVin1 } from 'assets/components/instructions/bike';
// import { makeGet } from 'services';
// import { AjaxResponse } from 'rxjs/ajax';
// import {
//   updateWindhsieldRepairReplaceDecision
// } from 'redux/root.actions';

const InstructionWrapper = styled.div`
  display: flex;
  min-height: var(--view-height);
  max-height: var(--view-height);
  overflow: hidden;
  position: relative;
`;

const InstructionWrapperCol1 = styled.div`
  background: linear-gradient(270deg, #ebf1f8 0%, #ffffff 100%);
  backdrop-filter: blur(1.5rem);
  padding: 2.75rem 3.5rem;
  padding-top: 2.875rem;
  flex: 1;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const InstructionWrapperCol2 = styled.div`
  background-color: #fff;
  width: var(--toolbar-width);
  margin: 0 auto;
`;

export type Params = {
  imageType: string;
  vehicleType: string;
};

function Instructions() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { imageType, vehicleType } = useParams<Params>();
  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);

  const {
    styles: { colorPrimaryButtonText },
  } = useTheme();
  const {
    instructions,
    captureImage: { addMoreImages },
    dispatchSetInstructionsSelectedLocationIndex
  } = usePhotoSeriesStore();

  // const dispatch = useDispatch();

  const imageSubType = instructions.subType?.subTypes[instructions.subType?.selectedSubTypeIndex].subTypeDigit;
  const nextPath = `/capture/${photoSeries.id}/image-type/${imageType}/vehicle-type/${vehicleType}/image-subType/${imageSubType}`;

  const itsLocationSubDomain = pathname.endsWith(InstructionsSubDomains.location);
  const { currentImagesCaptured, totalImagesToBeCapture } = instructions;
  const shouldAddMoreImages = addMoreImages.itsAddMoreImages || Number(imageType) === ImageTypeKeys.Additional;
  const additionalImagesCaptured = addMoreImages.totalImagesCaptured;

  const inProgressDamageCapture = useSelector(getInProgressDamageCapture);
  const additionalImagesPerDamage = useSelector(getAdditionalImagesPerDamage);
  const damageCaptureProgress = useSelector(getDamageCaptureProgress);
  // const selectedWorkflow = useSelector(selectSelectedWorkflow);

  const { fileInput, mode } = useNativeCameraUpload(nextPath);

  // TODO (Kentti, 05.08): Uncomment or delete this code after AI validation of close-ups first release is implemented
  // const additionalSettings = selectedWorkflow.imageTypeSettings.find((s: ImageTypeSettings) =>
  // s.enabled && s.imageType === ImageTypeKeys.Additional);
  // const isCloseUpsEnabled = additionalSettings &&
  //   additionalSettings.imageSubTypes.some((cfg: any) => cfg.imageSubType === ADDITIONAL_SUB_TYPES.FIRST_CLOSE_UP);
  // const isAllCloseUpsCaptured = isCloseUpsEnabled &&
  //   shouldAddMoreImages &&
  //   damageCaptureProgress &&
  //   damageCaptureProgress.total !== 0 &&
  //   damageCaptureProgress.current === damageCaptureProgress.total;

  // useEffect(() => {
  //   const makeWrrReparibilityRequest = photoSeries.manualDamageLabellingEnabled &&
  //     photoSeries.windshieldRepairReplaceDecisionEnabled &&
  //     isAllCloseUpsCaptured &&
  //     photoSeries.windshieldRepairReplaceDecision !== WindshieldRepairReplaceDecision.Calculating;

  //   if (makeWrrReparibilityRequest) {
  //     dispatch(updateWindhsieldRepairReplaceDecision(WindshieldRepairReplaceDecision.Calculating));
  //     makeGet(`capture/${photoSeries.id}/detect-ws-repairability`).subscribe({
  //       next: (response: AjaxResponse<any>) => {
  //         dispatch(updateWindhsieldRepairReplaceDecision(response.response));
  //       },
  //       error: () => {
  //         // no-op
  //       }
  //     });
  //   }
  // }, [photoSeries, isAllCloseUpsCaptured, dispatch, damageCaptureProgress, shouldAddMoreImages]);

  function onClickHandler() {
    const location = instructions.instructions && instructions.instructions.find((l) => l.subTypeDigit === imageSubType);
    const hasNextLocation = itsLocationSubDomain && location!.selectedLocationIndex !== location!.locations.length - 1;

    if (hasNextLocation) {
      dispatchSetInstructionsSelectedLocationIndex(location!.selectedLocationIndex + 1, imageSubType);
    } else if (mode === CameraMode.InBrowser) {
      const captureView = `/capture/${photoSeries.id}/image-type/${imageType}/vehicle-type/${vehicleType}/image-subType/${imageSubType}`;
      history.push(captureView);
    } else if (mode === CameraMode.Native) {
      fileInput?.click();
    }
  }

  const getIcon = React.useMemo(() => {
    let node = <></>;
    const isMotorcycle = Number(vehicleType) === VehicleTypeKeys.Motorcycle;
    const IconFc = IMAGE_TYPES[Number(imageType) as ImageTypeNumericKey].icon;

    if (shouldAddMoreImages) {
      node = (
        <SvgWrapper size="small">
          <Additional />
        </SvgWrapper>
      );
    } else {
      node = <SvgWrapper size="small">{isMotorcycle && Number(imageType) === ImageTypeKeys.Vin ? <BikeVin1 /> : <IconFc />}</SvgWrapper>;
    }
    return node;
  }, [imageType, shouldAddMoreImages, vehicleType]);

  const progressBar: {
    currentStep?: number | null;
    totalSteps?: number | null;
    textNode?: string;
    icon: React.ReactSVGElement | React.ReactElement;
  } = {
    icon: getIcon,
    currentStep: currentImagesCaptured,
    totalSteps: totalImagesToBeCapture,
    textNode: undefined
  };

  return (
    <Translate>
      {({ translate }) => {
        if (shouldAddMoreImages && inProgressDamageCapture && damageCaptureProgress) {
          progressBar.currentStep = damageCaptureProgress.current + 1;
          progressBar.totalSteps = damageCaptureProgress.total * additionalImagesPerDamage;
        } else if (shouldAddMoreImages) {
          progressBar.currentStep = null;
          progressBar.totalSteps = null;
          progressBar.textNode = `${additionalImagesCaptured} ${translate(TRANSLATIONS_VALUES_KEYS.vehicle_scan.images)}`;
        }

        return (
          <InstructionWrapper className="wrapper">
            <InstructionWrapperCol1>
              <ColumnLeft />
            </InstructionWrapperCol1>
            <InstructionWrapperCol2>
              <ActionColumn
                topRow={{
                  progressBar,
                  damageLabelling: {
                    enabled: false
                  }
                }}
                actionButton={{
                  icon: (
                    <AccessibleIcon label={<Translate id={TRANSLATIONS_VALUES_KEYS.accessibility.next_btn_text} />}>
                      <SvgWrapper size="small" fill={colorPrimaryButtonText}>
                        <IconForwardArrow />
                      </SvgWrapper>
                    </AccessibleIcon>
                  ),
                  onClickHandler,
                  animate: true
                }}
                bottomRow={{}}
              />
            </InstructionWrapperCol2>
          </InstructionWrapper>
        );
      }}
    </Translate>
  );
}

export default Instructions;
