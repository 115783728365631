/* eslint-disable max-len */
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { getPhotoSeries, selectCurrentUser } from 'redux/root.selectors';
import { useSelector } from 'react-redux';
import { ButtonContent, Button, RingLoader, AccessibleIcon } from 'components/partials';
import { useTheme } from 'hooks';
import { useParams } from 'react-router-dom';
import { makeGet, makeGetFile } from 'services';
import { AjaxResponse } from 'rxjs/ajax';
import { useState, useEffect, useCallback } from 'react';
import { isScreenPortrait } from 'utils';
import { PhotoSeries, WindshieldRepairReplaceDecision as PhotoSeriesWindshieldRepairDecision } from 'redux/photoseries/photoseries';
import { LandscapeLock } from 'components/containers';
import { SvgWrapper, Eco } from 'assets';
import { ReactComponent as IconCloseBtn } from 'assets/svgs/icn-feedback-close-btn.svg';
import { ReactComponent as IconDownload } from 'assets/svgs/icn-download.svg';
import { ReactComponent as IconCalendar } from 'assets/svgs/icn-calendar.svg';

const Title = styled.h2<{paddingTop?: string}>`
  font-size: 1.75rem;
  padding-top: ${(props) => props.paddingTop || '7rem'};
`;

const Body = styled.div`
  font-style: normal;
  font-size: 1.125rem;
  line-height: 200%;
  padding-top: 1rem;
`;

const Actions = styled.div<{isPortrait?: boolean}>`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  gap: 1rem;
  padding: 3rem;

  button {
    min-width: 45%;
  }

  ${(props) => (props.isPortrait ? 'flex-wrap: wrap;' : '')}
`;

const { user_feedback, vehicle_scan } = TRANSLATIONS_VALUES_KEYS;

const InspectionSummary = styled.div`
  padding-top: 2.5rem;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
`;

const InspectionSummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.125rem;

  h2 {
    margin-bottom: 0;
  }
`;

const RepairDecision = styled.div<{ repairDecision: PhotoSeriesWindshieldRepairDecision, styles: any }>`
  margin-top: 1rem;
  border: 1px solid ${({ repairDecision, styles }) => repairDecision === PhotoSeriesWindshieldRepairDecision.Replace ? '#D7DCE2' : styles.colorGreenSecondary};
  border-radius: 0.625rem;
  padding: 1rem;
  background-color: ${({ repairDecision, styles }) => repairDecision === PhotoSeriesWindshieldRepairDecision.Replace ? 'white' : styles.colorGreenLight};
`;

const RepairDecisionHeader = styled.div<{ repairDecision: PhotoSeriesWindshieldRepairDecision, styles: any }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    font-size: 1.125rem;
    margin-bottom: 0;
    ${({ repairDecision, styles }) => repairDecision === PhotoSeriesWindshieldRepairDecision.Repair && `color: ${styles.colorGreenPrimary};`}
  }

  margin-bottom: .5rem;
`;

const RepairDecisionDescription = styled.div`
  color: #000000;
  text-align: left;
  font-size: 0.9rem;
  line-height: 1.4rem;
`;

const RepairDecisionActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  button {
    min-width: 45%;
  }
`;

interface WindshieldRepairReplaceDecisionProps {
  downloadComplete?: () => void;
  noThanks?: () => void;
  photoSeriesId: string;
  vin: string;
  repairDecision: PhotoSeriesWindshieldRepairDecision;
  bookingUrl: string | undefined;
  customerName: string;
}

const WindshieldRepairReplaceDecision = (props: WindshieldRepairReplaceDecisionProps) => {
  const { styles } = useTheme();
  const { downloadComplete, noThanks, photoSeriesId, vin, repairDecision, bookingUrl, customerName } = props;
  const [isDownloading, setIsDownloading] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(false);

  const hasBookingUrl = bookingUrl && bookingUrl !== '';

  useEffect(() => {
    if (!hasBookingUrl || repairDecision === PhotoSeriesWindshieldRepairDecision.Calculating) return;
    const timeout = setTimeout(() => {
      setShowCloseButton(true);
    }, 4500);
    return () => clearTimeout(timeout);
  }, [hasBookingUrl, repairDecision]);

  const redirectToBookATime = () => {
    if (hasBookingUrl) {
      window.location.replace(bookingUrl);
    }
  };

  if (repairDecision === PhotoSeriesWindshieldRepairDecision.Calculating) {
    return (
      <LandscapeLock>
        <InspectionSummary>
          <InspectionSummaryHeader>
            <Title data-testid="inspection-summary-title" paddingTop="0rem">
              <Translate id={user_feedback.inspection_summary} />
            </Title>
          </InspectionSummaryHeader>
          <RingLoaderWrapper className="mt-5">
            <RingLoader
              fontSize="0.5rem"
              color="white"
              bgColor={styles.colorPrimaryButtonBackground}
            />
          </RingLoaderWrapper>
          <div className="mt-5">
            <Translate id={user_feedback.windshield_inspection_summary_loading} />
          </div>
        </InspectionSummary>
      </LandscapeLock>
    );
  }

  return (
    <LandscapeLock>
      <InspectionSummary>
        <InspectionSummaryHeader>
          <Title data-testid="inspection-summary-title" paddingTop="0rem">
            <Translate id={user_feedback.inspection_summary} />
          </Title>
          {
            showCloseButton && (
              <AccessibleIcon label="Close inspection summary">
                <SvgWrapper size="medium" role="button" tabIndex={-1} onClick={noThanks} data-testid="close-comment-button">
                  <IconCloseBtn />
                </SvgWrapper>
              </AccessibleIcon>
            )
          }
        </InspectionSummaryHeader>
        <RepairDecision repairDecision={repairDecision} styles={styles}>
          <RepairDecisionHeader repairDecision={repairDecision} styles={styles}>
            <h5>
              {repairDecision === PhotoSeriesWindshieldRepairDecision.Repair ? <Translate id={user_feedback.windshield_inspection_summary_repair} /> : <Translate id={user_feedback.windshield_inspection_summary_replace} />}
            </h5>
            <Eco
              stroke={repairDecision === PhotoSeriesWindshieldRepairDecision.Repair ? styles.colorGreenPrimary : styles.colorParagraph}
              fill={repairDecision === PhotoSeriesWindshieldRepairDecision.Repair ? styles.colorGreenLight : 'white'}
            />
          </RepairDecisionHeader>
          <RepairDecisionDescription>
            <Translate id={user_feedback.windshield_inspection_summary_information} />
          </RepairDecisionDescription>
        </RepairDecision>
        <RepairDecisionDescription className="mt-3">
          <Translate
            id={vehicle_scan.seriesComplete_publicUser_text}
            data={{ publicUserPhotoSeriesRecipient: customerName }}
          />
        </RepairDecisionDescription>
        <RepairDecisionActions>
          <DownloadImagesButton
            photoSeriesId={photoSeriesId}
            downloadComplete={() => {
              if (downloadComplete) {
                downloadComplete();
              }
              setIsDownloading(false);
            }}
            vin={vin}
            disabled={isDownloading}
            downloadClicked={() => setIsDownloading(true)}
          />
          {
            hasBookingUrl
              ? (
                <Button
                  type="button"
                  testId="btnBookATime"
                  bg={styles.colorGreenPrimary}
                  color="white"
                  onClick={redirectToBookATime}
                >
                  <SvgWrapper
                    size="small"
                    stroke="white"
                    onClick={redirectToBookATime}
                  >
                    <IconCalendar />
                  </SvgWrapper>
                  <ButtonContent>
                    <Translate id={user_feedback.inspection_summary_book_a_time} />
                  </ButtonContent>
                </Button>
              )
              : (
                <Button
                  type="button"
                  testId="btnNoThanks"
                  onClick={noThanks}
                  bg={styles.colorSecondaryButtonBackground}
                  color={styles.colorSecondaryButtonText}
                  hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
                  hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
                >
                  <ButtonContent>
                    <Translate id={user_feedback.btn_no_thanks} />
                  </ButtonContent>
                </Button>
              )
          }
        </RepairDecisionActions>
      </InspectionSummary>
    </LandscapeLock>
  );
};

interface DownloadImagesProps {
  downloadComplete?: () => void;
  noThanks?: () => void;
  photoSeriesId: string;
  vin: string;
}

const DownloadImages = (props: DownloadImagesProps) => {
  const { downloadComplete, noThanks, photoSeriesId, vin } = props;
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const isPortrait = isScreenPortrait();

  return (
    <>
      <Title data-testid="thank-you-title">
        <Translate id={user_feedback.title_thankyou} />
      </Title>
      <Body data-testid="thank-you-description">
        {
          isDownloading
            ? <Translate id={user_feedback.title_download_will_start_shorty} />
            : <Translate id={user_feedback.title_download_images} />
        }
        <Actions isPortrait={isPortrait}>
          <DownloadImagesButton
            photoSeriesId={photoSeriesId}
            downloadComplete={downloadComplete}
            vin={vin}
            disabled={isDownloading}
            downloadClicked={() => setIsDownloading(true)}
          />
          {
            !isDownloading && noThanks && (
              <Button
                type="button"
                testId="btnNoThanks"
                onClick={noThanks}
              >
                <ButtonContent>
                  <Translate id={user_feedback.btn_no_thanks} />
                </ButtonContent>
              </Button>
            )
          }
        </Actions>
      </Body>
    </>
  );
};

interface DownloadImagesButtonProps {
  downloadComplete?: () => void;
  downloadClicked?: () => void | undefined;
  disabled: boolean;
  photoSeriesId: string,
  vin: string,
}

const downloadImages = (imageUrl: string, vin: string) => {
  const anchor = document.createElement('a');
  anchor.href = imageUrl;
  anchor.download = `${vin} images.zip`;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(imageUrl);
};

const DownloadImagesButton = (props: DownloadImagesButtonProps) => {
  const { styles } = useTheme();
  const { downloadComplete, photoSeriesId, vin, downloadClicked, disabled } = props;

  const handleDownloadImages = () => {
    if (downloadClicked) downloadClicked();

    makeGet(`files/${photoSeriesId}/token?entityType=1`).subscribe(
      ({ response }: AjaxResponse<any>) => {
        makeGetFile(`files/${photoSeriesId}/download?token=${response.token}`).subscribe(
          ({ response }: AjaxResponse<any>) => {
            if (response?.size) {
              downloadImages(window.URL.createObjectURL(response), vin);
              if (downloadComplete) {
                downloadComplete();
              }
            }
          }
        );
      }
    );
  };

  return (
    <Button
      type="button"
      testId="btnDownloadImages"
      bg={styles.colorSecondaryButtonBackground}
      color={styles.colorSecondaryButtonText}
      hoverBorderColor={styles.colorSecondaryButtonBackgroundDark}
      hoverShadowColor={styles.colorSecondaryButtonBackgroundLight}
      onClick={handleDownloadImages}
      disabled={disabled}
    >
      <SvgWrapper
        size="small"
        fill={styles.colorPrimaryButtonText}
        stroke={styles.colorPrimaryButtonText}
        onClick={(event) => {
          handleDownloadImages();
          event.preventDefault();
        }}
      >
        <IconDownload />
      </SvgWrapper>
      <ButtonContent>
        <Translate id={user_feedback.btn_download_images} />
      </ButtonContent>
    </Button>
  );
};

interface RedirectToCompanyProps {
  customerName: string,
  returnUrl: string
}

const RedirectToCompany = (props: RedirectToCompanyProps) => {
  const { customerName, returnUrl } = props;
  const [counter, setCounter] = useState<number>(10);

  useEffect(() => {
    const timer = setInterval(() => {
      if (counter > 0) setCounter(counter - 1);
    }, 1000);

    if (counter === 0) {
      window.location.replace(returnUrl);
    }

    return () => clearInterval(timer);
  }, [counter, returnUrl]);

  return (
    <>
      <Title data-testid="thank-you-title">
        <Translate id={user_feedback.title_thankyou} />
      </Title>
      <Body data-testid="thank-you-description">
        <div className="pr-5 pl-5">
          <Translate
            id={user_feedback.title_redirect}
            data={{ publicUserPhotoSeriesRecipient: customerName, unitSeconds: counter }}
          />
        </div>
      </Body>
    </>
  );
};

const RingLoaderWrapper = styled.div`
  padding-top: 3rem;
`;

interface RedirectToSigningProps {
  photoSeriesId: string,
}

const RedirectToSigning = (props: RedirectToSigningProps) => {
  const { styles } = useTheme();
  const { photoSeriesId } = props;

  const queryPhotoSeries = useCallback(() => {
    makeGet(`photoSeries/${photoSeriesId}`).subscribe(
      ({ response }: AjaxResponse<any>) => {
        if (response.reportSigningUrl && response.reportSigningUrl.length > 0) {
          window.location.replace(response.reportSigningUrl);
        }
      }
    );
  }, [photoSeriesId]);

  useEffect(() => {
    const timer = setInterval(() => queryPhotoSeries(), 5000);
    return () => clearInterval(timer);
  }, [queryPhotoSeries]);

  return (
    <>
      <Title data-testid="thank-you-title">
        <Translate id={user_feedback.title_thankyou} />
      </Title>
      <Body data-testid="thank-you-description">
        <Translate
          id={vehicle_scan.seriesComplete_report_signing_text}
        />
        <RingLoaderWrapper>
          <RingLoader
            fontSize="0.5rem"
            color="white"
            bgColor={styles.colorPrimaryButtonBackground}
          />
        </RingLoaderWrapper>
      </Body>
    </>
  );
};

interface Params {
  photoSeriesId: string;
}

const FeedbackThankYou = () => {
  const currentUser = useSelector(selectCurrentUser);
  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);

  const isSmartScanImageDownloadEnabled = photoSeries.smartScanImageDownloadEnabled === true;
  const isWindshieldRepairReplaceDecisionEnabled = photoSeries.windshieldRepairReplaceDecisionEnabled === true;

  const [showFinalPage, setShowFinalPage] = useState<boolean>(
    !isSmartScanImageDownloadEnabled && !isWindshieldRepairReplaceDecisionEnabled
  );

  const { photoSeriesId } = useParams<Params>();
  const { customerName, returnUrl } = currentUser;

  if (showFinalPage && photoSeries.reportSigningRequired) {
    return <RedirectToSigning photoSeriesId={photoSeries.id} />;
  }

  if (showFinalPage && returnUrl) {
    return (
      <RedirectToCompany
        customerName={customerName}
        returnUrl={returnUrl}
      />
    );
  }

  if (showFinalPage) {
    return (
      <>
        <Title data-testid="thank-you-title">
          <Translate id={user_feedback.title_thankyou} />
        </Title>
        <Body data-testid="thank-you-description">
          <div className="pr-5 pl-5">
            <Translate
              id={vehicle_scan.seriesComplete_publicUser_text}
              data={{ publicUserPhotoSeriesRecipient: customerName }}
            />
          </div>
        </Body>
      </>
    );
  }

  if (photoSeries.windshieldRepairReplaceDecision !== undefined) {
    return (
      <WindshieldRepairReplaceDecision
        repairDecision={photoSeries.windshieldRepairReplaceDecision}
        photoSeriesId={photoSeriesId}
        vin={photoSeries.vehicle.vin}
        downloadComplete={() => {
          if (!photoSeries.windshieldRepairReplaceBookingUrl || photoSeries.windshieldRepairReplaceBookingUrl.length === 0) {
            setShowFinalPage(true);
          }
        }}
        noThanks={() => setShowFinalPage(true)}
        bookingUrl={photoSeries.windshieldRepairReplaceBookingUrl}
        customerName={customerName}
      />
    );
  }

  return (
    <DownloadImages
      photoSeriesId={photoSeriesId}
      vin={photoSeries.vehicle.vin}
      downloadComplete={() => setShowFinalPage(true)}
      noThanks={() => setShowFinalPage(true)}
    />
  );
};

export default FeedbackThankYou;
