/* eslint-disable no-unused-vars */
import React from 'react';
import { AjaxResponse, ajax } from 'rxjs/ajax';
import { PhotoSeries, WindshieldRepairReplaceDecision } from 'redux/photoseries/photoseries';
import { IDamage } from 'redux/damages/damages';
import { ADDITIONAL_SUB_TYPES, IWorkflow, ImageTypeKeys, ImageTypeSettings, WINDSHIELD_SUB_TYPES } from 'redux/workflows/workflows';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { getInProgressDamageCapture } from 'redux/damages/damages.selectors';
import { makeGet, makeImageUpload, makePost } from 'services';
import { Config } from 'utils/camera-photo/media-services';
import { CameraPhoto, IMAGE_TYPES } from 'utils/camera-photo';
import { translateString } from '../../utils/photoseries.util';
import { CustomError, genericErrorNumberDenotation, genericErrors, getGeolocation, submitErrorLog } from 'utils';
import { PhotoSeriesStoreState, CameraSettings, ImageResponse, GenericError, CaptureImageState, InstructionsState, VerificationError } from '../root';
import { store } from 'redux/root.store';
import { saveDamages, updateWindhsieldRepairReplaceDecision } from 'redux/root.actions';
import { setCaptureInfoLastImageType } from '../capture-info/capture-info.actions';
import { DamageLabellingInstruction } from '../../containers/damage-labelling-instructions/damage-labelling-instructions';
import { getThumbnailUrl } from 'services/thumbnail';

const REQUEST_TIMEOUT_IN_SECONDS = 20;

type SetPhotoSeriesStoreState = React.Dispatch<React.SetStateAction<PhotoSeriesStoreState>>;

const setConfirmNoDamages = (confirmNoDamages: Function | null, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      confirmNoDamages
    }
  }));
};

const setCaptureImageIsLoading = (isLoading: boolean, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      isLoading,
    },
  }));
};

const setCaptureImageGenericError = (genericError: GenericError | null, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => {
    const prevCaptureImage = prevPhotoSeriesStoreState.captureImage;

    // If existing error message has higher (lower value) or same importance then don't overwrite existing error
    if (genericError && prevCaptureImage.genericError && prevCaptureImage.genericError.importance <= genericError.importance) {
      return prevPhotoSeriesStoreState;
    }

    return {
      ...prevPhotoSeriesStoreState,
      captureImage: {
        ...prevCaptureImage,
        genericError,
      },
    };
  });
};

const setCaptureImageHeadingText = (headingText: string, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      headingText,
    },
  }));
};

const setCaptureImageBottomText = (bottomText: string, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      bottomText,
    },
  }));
};

const setCaptureImageIsVinEntered = (isVinEntered: boolean, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      isVinEntered,
    },
  }));
};

const setCaptureImageIsPhotoConfirmed = (isPhotoConfirmed: boolean, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      isPhotoConfirmed,
    },
  }));
};

const setCaptureImageIsInShootMode = (isInShootMode: boolean, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => {
    const { cameraPhoto } = prevPhotoSeriesStoreState.captureImage;

    if (isInShootMode && cameraPhoto) cameraPhoto.continueStreaming();

    return {
      ...prevPhotoSeriesStoreState,
      captureImage: {
        ...prevPhotoSeriesStoreState.captureImage,
        isInShootMode,
      },
    };
  });
};

const setCaptureImageIsInDamageLabellingMode = (isInDamageLabellingMode: boolean, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      isInDamageLabellingMode,
      showDamageLabellingInstructions: isInDamageLabellingMode,
    },
  }));
};

const setCaptureImageDamageLabellingInstructionShown = (
  instruction: DamageLabellingInstruction,
  remove: boolean,
  setPhotoSeriesStoreState: SetPhotoSeriesStoreState
) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      shownDamageLabellingInstructions: remove
        ? prevPhotoSeriesStoreState.captureImage.shownDamageLabellingInstructions.filter((i) => i !== instruction)
        : [...prevPhotoSeriesStoreState.captureImage.shownDamageLabellingInstructions, instruction],
    },
  }));
};

const setCaptureImageImageResponse = (imageResponse: null | ImageResponse, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      imageResponse
    },
  }));
};

const setCapturedImageObjectUrl = (image: Blob, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => {
    let imageObjectUrl = prevPhotoSeriesStoreState.captureImage.imageObjectUrl;
    if (imageObjectUrl && imageObjectUrl.length > 0) {
      window.URL.revokeObjectURL(imageObjectUrl);
    }

    imageObjectUrl = window.URL.createObjectURL(image);

    return {
      ...prevPhotoSeriesStoreState,
      captureImage: {
        ...prevPhotoSeriesStoreState.captureImage,
        imageObjectUrl
      },
    };
  });
};

const setCleanCapturedImageObjectUrl = (setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => {
    let imageObjectUrl = prevPhotoSeriesStoreState.captureImage.imageObjectUrl;
    if (imageObjectUrl && imageObjectUrl.length > 0) {
      window.URL.revokeObjectURL(imageObjectUrl);
    }

    return {
      ...prevPhotoSeriesStoreState,
      captureImage: {
        ...prevPhotoSeriesStoreState.captureImage,
        imageObjectUrl: ''
      },
    };
  });
};

const setCaptureImageCameraPhoto = (cameraPhoto: null | CameraPhoto, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      cameraPhoto,
    },
  }));
};

const setCaptureImageCameraSettings = (cameraSettings: null | CameraSettings, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      cameraSettings,
    },
  }));
};

const setCaptureImageImageSubTypeTranslationKeys = (
  imageSubTypeTranslationKeys: { [key: number]: string },
  setPhotoSeriesStoreState: SetPhotoSeriesStoreState
) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      imageSubTypeTranslationKeys,
    },
  }));
};

const setCaptureImageAddMoreImagesTotalImagesCaptured = (setPhotoSeriesStoreState: SetPhotoSeriesStoreState, totalImagesCaptured?: number) => {
  setPhotoSeriesStoreState((prevState) => ({
    ...prevState,
    captureImage: {
      ...prevState.captureImage,
      addMoreImages: {
        ...prevState.captureImage.addMoreImages,
        totalImagesCaptured: totalImagesCaptured ?? prevState.captureImage.addMoreImages.totalImagesCaptured + 1,
      },
    },
  }));
};

const setCaptureImageAdditionalImageIndex = (setPhotoSeriesStoreState: SetPhotoSeriesStoreState, additionalImageIndex?: number) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      additionalImageIndex: additionalImageIndex ?? prevPhotoSeriesStoreState.captureImage.additionalImageIndex + 1,
    },
  }));
};

const setCaptureImageCurrentErrorCount = (setPhotoSeriesStoreState: SetPhotoSeriesStoreState, currentErrorCount?: number) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      currentErrorCount: currentErrorCount ?? prevPhotoSeriesStoreState.captureImage.currentErrorCount + 1,
    },
  }));
};

const setCaptureImageItsAddMoreImages = (itsAddMoreImages: boolean, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevState) => ({
    ...prevState,
    captureImage: {
      ...prevState.captureImage,
      addMoreImages: {
        ...prevState.captureImage.addMoreImages,
        itsAddMoreImages,
      },
    },
  }));
};

const setCaptureImageImageTypeTranslationKeys = (
  imageTypeTranslationKeys: { [key: number]: string },
  setPhotoSeriesStoreState: SetPhotoSeriesStoreState
) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      imageTypeTranslationKeys,
    },
  }));
};

const setCaptureImageIsGdprAccepted = (isGdprAccepted: boolean, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      isGdprAccepted,
    },
  }));
};

const setCaptureImageNativeCameraPhoto = (nativeCameraPhoto: Blob, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      nativeCameraPhoto,
    },
  }));
};

const setImageComment = (comment: string, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureImage: {
      ...prevPhotoSeriesStoreState.captureImage,
      imageComment: comment,
    },
  }));
};

const setCaptureInfoImagesCaptured = (setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  setPhotoSeriesStoreState((prevPhotoSeriesStoreState) => ({
    ...prevPhotoSeriesStoreState,
    captureInfo: {
      ...prevPhotoSeriesStoreState.captureInfo,
      imagesCaptured: prevPhotoSeriesStoreState.captureInfo.imagesCaptured + 1
    },
  }));
};

let imageUploadController: AbortController | null;

interface ProcessSaveImageResponseProps {
  instructions: InstructionsState;
  captureImage: CaptureImageState;
  photoSeries: PhotoSeries;
  isInDamageLabellingMode: boolean;
  onRendererSubTypeView: Function;
  onRenderCaptureInfoView: Function;
  setPhotoSeriesStoreState: SetPhotoSeriesStoreState;
  // eslint-disable-next-line no-unused-vars
  onSetShownValidationModal: (shownValidationModal: boolean) => void;
  imageType: number;
}

const processSaveImageResponse = (props: ProcessSaveImageResponseProps) => {
  const { captureImage, onSetShownValidationModal, setPhotoSeriesStoreState } = props;
  const imageResponse = captureImage.imageResponse;

  //  If any errors returned by API, show feedback modal
  if (imageResponse && imageResponse.verificationErrors?.length) {
    onSetShownValidationModal(true);
    setCaptureImageCurrentErrorCount(setPhotoSeriesStoreState);
    return;
  }

  renderNextView(props);
};

const renderNextView = ({
  instructions,
  captureImage,
  onRendererSubTypeView,
  onRenderCaptureInfoView,
  setPhotoSeriesStoreState,
  photoSeries,
  isInDamageLabellingMode,
  imageType,
}: ProcessSaveImageResponseProps) => {
  if (captureImage.currentErrorCount > 0) setCaptureImageCurrentErrorCount(setPhotoSeriesStoreState, 0);

  const { subType } = instructions;

  const inProgresDamageCapture = getInProgressDamageCapture(store.getState());
  if (photoSeries.isManualDamageLabellingEnabled(imageType, !!inProgresDamageCapture) && !isInDamageLabellingMode) {
    setCaptureImageIsInDamageLabellingMode(true, setPhotoSeriesStoreState);
    return;
  }

  setCaptureImageIsInDamageLabellingMode(false, setPhotoSeriesStoreState);
  setConfirmNoDamages(null, setPhotoSeriesStoreState);
  setCleanCapturedImageObjectUrl(setPhotoSeriesStoreState);

  if (subType && subType.selectedSubTypeIndex !== subType.subTypes.length! - 1) {
    // If ImageType has more ImageSubTypes, render <Instructions/> for next subtype
    onRendererSubTypeView();
    return;
  }

  onRenderCaptureInfoView();
};

const handleRetakePhoto = (setPhotoSeriesStoreState: SetPhotoSeriesStoreState, onAction?: Function) => {
  if (imageUploadController) {
    imageUploadController.abort();
  }

  setCleanCapturedImageObjectUrl(setPhotoSeriesStoreState);
  setCaptureImageGenericError(null, setPhotoSeriesStoreState);
  setCaptureImageImageResponse(null, setPhotoSeriesStoreState);
  setCaptureImageIsPhotoConfirmed(false, setPhotoSeriesStoreState);
  setCaptureImageIsInShootMode(true, setPhotoSeriesStoreState);

  if (onAction) onAction();
};

interface HandleImageUploadCompletedProps extends ProcessSaveImageResponseProps {
  isInShootMode: boolean;
  isPhotoConfirmed: boolean;
  onActionAfterImageUploadAjax?: Function;
}

const handleImageUploadCompleted = (response: any, props: HandleImageUploadCompletedProps) => {
  if (imageUploadController) {
    imageUploadController.abort();
  }

  setCaptureImageImageResponse(response, props.setPhotoSeriesStoreState);
  props.onActionAfterImageUploadAjax?.(response.imageId, response.verificationErrors as VerificationError[]);

  if (props.isPhotoConfirmed) {
    processSaveImageResponse(props);
  }

  // set is loading to false here
  setCaptureImageIsLoading(false, props.setPhotoSeriesStoreState);
  setCaptureInfoLastImageType(props.imageType, props.setPhotoSeriesStoreState);
};

interface UploadImageProps extends HandleImageUploadCompletedProps {
  photoSeriesId: string;
  data: Record<string, unknown>;
  image: Blob;
  imageType: number;
  imageSubType?: number;
}

const uploadImage = (props: UploadImageProps) => {
  const { photoSeriesId, data, image, setPhotoSeriesStoreState } = props;

  const handleError = (errorObj: CustomError) => {
    if (imageUploadController?.signal?.aborted) {
      imageUploadController = null;
      return;
    }

    const { name } = errorObj;
    const { vehicle_scan, server_error } = TRANSLATIONS_VALUES_KEYS;

    submitErrorLog(`'${name}' occurred when trying to upload image to API`, errorObj);
    setCaptureImageGenericError(
      {
        errors: [{ error: genericErrorNumberDenotation.genericError, errorDetails: genericErrors.serverError }],
        onAction: () => {
          handleRetakePhoto(setPhotoSeriesStoreState);
        },
        text: translateString(vehicle_scan.retry_btn),
        title: translateString(server_error.title),
        importance: 1,
      },
      setPhotoSeriesStoreState
    );
  };

  if (imageUploadController) {
    imageUploadController.abort();
  }

  imageUploadController = new AbortController();
  const timeoutId = setTimeout(() => {
    if (imageUploadController) {
      imageUploadController.abort();
    }

    submitErrorLog('"SaveImage" request timed out when trying to upload image to API', null);

    const { vehicle_scan, timeout_error } = TRANSLATIONS_VALUES_KEYS;

    setCaptureImageGenericError(
      {
        errors: [{ error: genericErrorNumberDenotation.genericError, errorDetails: genericErrors.timeoutError }],
        onAction: () => {
          handleRetakePhoto(setPhotoSeriesStoreState);
        },
        text: translateString(vehicle_scan.retry_btn),
        title: translateString(timeout_error.title),
        importance: 1,
      },
      setPhotoSeriesStoreState
    );
  }, REQUEST_TIMEOUT_IN_SECONDS * 1000);

  makeImageUpload(`capture/${photoSeriesId}/images`, image, data, imageUploadController.signal)
    .then((response: Response) => {
      if (!response.ok) {
        throw new Error('Received not ok response!');
      }

      return response.json();
    })
    .then((response: any) => {
      clearTimeout(timeoutId);
      handleImageUploadCompleted(response, props);
    })
    .catch((error) => {
      clearTimeout(timeoutId);
      setCaptureImageIsLoading(false, setPhotoSeriesStoreState);
      handleError(error as CustomError);
    });
};

interface OnTakePhoto extends UploadImageProps {
  imageType: number;
  imageSubType?: number;
  customImageTypeId: string | null;
  sequenceNo?: number;
}

type RelatedObject = {
  id: string,
  imageId: string,
  type: RelatedObjectType
}

enum RelatedObjectType {
  Damage = 1
}

interface OnTakePhotoProps extends Omit<OnTakePhoto, 'data' | 'photoSeriesId'> {
  geolocationEnabled: boolean;
  relatedObject?: RelatedObject | undefined
}

const onTakePhoto = (props: OnTakePhotoProps) => {
  const {
    image,
    imageType,
    imageSubType,
    customImageTypeId,
    photoSeries,
    onActionAfterImageUploadAjax,
    geolocationEnabled,
    sequenceNo,
    relatedObject,
    setPhotoSeriesStoreState
  } = props;

  // Set capture image object url
  setCapturedImageObjectUrl(image, setPhotoSeriesStoreState);

  const data: Record<string, number | undefined | Record<string, number> | Record<string, string> | string> = {
    imageType,
    imageSubType
  };

  if (imageType === ImageTypeKeys.Exterior || imageType === ImageTypeKeys.Windshield || imageType === ImageTypeKeys.Interior) {
    data.imageSubType = imageSubType;
  } else if (imageType === ImageTypeKeys.Additional) {
    data.sequenceNo = sequenceNo;
    if (relatedObject) {
      data.relatedObject = {
        id: relatedObject.id,
        type: relatedObject.type.toString(),
        imageId: relatedObject.imageId
      };
    }
  } else if (imageType === ImageTypeKeys.Custom && customImageTypeId !== null) {
    delete data.imageSubType;
    data.customImageSubTypeId = customImageTypeId;
    data.sequenceNo = sequenceNo;
  }

  const uploadImageProps = {
    data,
    photoSeriesId: photoSeries.id,
    onActionAfterImageUploadAjax,
    ...props,
  };

  if (geolocationEnabled) {
    getGeolocation(
      ({ coords: { accuracy, latitude, longitude } }) => {
        data.location = {
          accuracy,
          latitude,
          longitude,
        };
        uploadImage(uploadImageProps);
      },
      () => uploadImage(uploadImageProps),
      { enableHighAccuracy: true, maximumAge: 0, timeout: 4000 }
    );
  } else {
    uploadImage(uploadImageProps);
  }
};

interface HandleCameraButtonClick extends UploadImageProps {
  imageType: number;
  imageSubType?: number;
  customImageTypeId: string | null;
  sequenceNo?: number;
  cameraPhoto: null | CameraPhoto;
  photoSeries: PhotoSeries;
}

export interface HandleCameraButtonClickProps extends Omit<HandleCameraButtonClick, 'data' | 'photoSeriesId' | 'image'> {
  geolocationEnabled: boolean;
  draftDamages?: IDamage[];
  relatedObject?: RelatedObject | undefined,
  workflow: IWorkflow
}

const handleShootModeClick = (props: HandleCameraButtonClickProps) => {
  // Photo has not been taken yet
  const {
    setPhotoSeriesStoreState,
    cameraPhoto,
  } = props;

  if (!cameraPhoto) return;

  setCaptureImageIsLoading(true, setPhotoSeriesStoreState);
  setCaptureImageIsInShootMode(false, setPhotoSeriesStoreState);
  setCaptureImageImageResponse(null, setPhotoSeriesStoreState);

  const config = { imageType: IMAGE_TYPES.JPG, imageCompression: 0.5 };

  cameraPhoto!.getImage(config as Config).then((response) => {
    const { forUpload } = response as { forUpload: Blob | null };

    onTakePhoto({
      ...props,
      image: forUpload!,
      onActionAfterImageUploadAjax(imageId: string | null, verificationErrors: VerificationError[] | null) {
        props.onActionAfterImageUploadAjax?.(imageId, verificationErrors);
      },
    });
  });
};

const handleConfirmPhotoClick = (props: HandleCameraButtonClickProps) => {
  const {
    imageType,
    captureImage,
    setPhotoSeriesStoreState,
  } = props;

  setCaptureImageIsPhotoConfirmed(true, setPhotoSeriesStoreState);

  if (!captureImage.isLoading && !captureImage.isInShootMode) {
    // Image has been saved and user clicked confirm btn
    const additionalImageRequired = imageType === ImageTypeKeys.Additional || captureImage.addMoreImages.itsAddMoreImages;
    if (additionalImageRequired) {
      setCaptureImageAddMoreImagesTotalImagesCaptured(setPhotoSeriesStoreState, captureImage.addMoreImages.totalImagesCaptured + 1);
      setCaptureImageAdditionalImageIndex(setPhotoSeriesStoreState, captureImage.additionalImageIndex + 1);
    }

    if (captureImage.imageResponse?.imageId) {
      handleSaveImageComment(captureImage.imageResponse?.imageId, captureImage.imageComment, setPhotoSeriesStoreState);
    }

    setCaptureInfoImagesCaptured(setPhotoSeriesStoreState);
    processSaveImageResponse(props);
  }
};

const handleDamageLabellingModeClick = (props: HandleCameraButtonClickProps) => {
  const {
    photoSeries: {
      id: photoSeriesId,
      manualDamageLabellingConfirmNoDamages
    },
    captureImage: {
      imageResponse: image
    },
    draftDamages,
    workflow,
    setPhotoSeriesStoreState,
  } = props;

  // Check if closeups are reqiuired
  const additionalSettings = workflow.imageTypeSettings.find((s: ImageTypeSettings) => s.enabled && s.imageType === ImageTypeKeys.Additional);
  const isCloseUpsEnabled = additionalSettings &&
    additionalSettings.imageSubTypes.some((cfg: any) => cfg.imageSubType === ADDITIONAL_SUB_TYPES.FIRST_CLOSE_UP);
  const analyzeWindshieldFromOutside = props.photoSeries.windshieldRepairReplaceDecisionEnabled &&
    props.imageType === ImageTypeKeys.Windshield &&
    props.imageSubType === WINDSHIELD_SUB_TYPES.FROM_OUTSIDE;

  if (draftDamages && draftDamages.length > 0) {
    setCaptureImageIsLoading(true, setPhotoSeriesStoreState);
    makePost(`capture/${photoSeriesId}/images/${image!.imageId}/damages`, draftDamages).subscribe(({ response }: AjaxResponse<any>) => {
      const damages = response as IDamage[];

      store.dispatch(saveDamages(image!.imageId, damages));

      // Send windshield repairability only if closeups are not enabled
      // Temporarly disable closeups for windshield damage analysis
      // TODO (Kentti, 05.08): Change this condition check after AI validation of close-ups first release is implemented
      if (analyzeWindshieldFromOutside && (isCloseUpsEnabled || !isCloseUpsEnabled)) {
        store.dispatch(updateWindhsieldRepairReplaceDecision(WindshieldRepairReplaceDecision.Calculating));
        makeGet(`capture/${photoSeriesId}/detect-ws-repairability`).subscribe({
          next: (response: AjaxResponse<any>) => {
            store.dispatch(updateWindhsieldRepairReplaceDecision(response.response));
          },
          error: () => {
            // no-op
          }
        });
      }

      // TODO (Kentti, 05.08): Uncomment or delete this code after AI validation of close-ups first release is implemented
      // if (analyzeWindshieldFromOutside && !isCloseUpsEnabled) {
      //   store.dispatch(updateWindhsieldRepairReplaceDecision(WindshieldRepairReplaceDecision.Calculating));
      //   makeGet(`capture/${photoSeriesId}/detect-ws-repairability`).subscribe({
      //     next: (response: AjaxResponse<any>) => {
      //       store.dispatch(updateWindhsieldRepairReplaceDecision(response.response));
      //     },
      //     error: () => {
      //       // no-op
      //     }
      //   });
      // }

      // Cache damage thumbnails for better UX
      damages.forEach((damage) => {
        ajax({
          url: getThumbnailUrl(damage.imageFileName, damage.id, true),
          method: 'GET'
        }).subscribe(
          () => {},
          () => {}
        );
      });

      setCaptureImageIsLoading(false, setPhotoSeriesStoreState);
      renderNextView(props);
    });
  } else if (manualDamageLabellingConfirmNoDamages) {
    setConfirmNoDamages(() => renderNextView(props), setPhotoSeriesStoreState);
  } else {
    renderNextView(props);
  }
};

const handleCameraButtonClick = (props: HandleCameraButtonClickProps) => {
  const {
    isInShootMode,
    isPhotoConfirmed,
    isInDamageLabellingMode
  } = props;

  if (isInShootMode) {
    handleShootModeClick(props);
  } else if (isInDamageLabellingMode) {
    handleDamageLabellingModeClick(props);
  } else if (!isPhotoConfirmed) {
    handleConfirmPhotoClick(props);
  }
};

const handleNativeCameraPhotoTaken = (setPhotoSeriesStoreState: SetPhotoSeriesStoreState, photo: Blob) => {
  // Photo has not been taken yet
  setCaptureImageNativeCameraPhoto(photo, setPhotoSeriesStoreState);
};

const uploadNativeCameraPhoto = (props: HandleCameraButtonClickProps, photo: Blob) => {
  // Photo has not been uploaded yet
  const {
    setPhotoSeriesStoreState,
  } = props;

  setCaptureImageIsLoading(true, setPhotoSeriesStoreState);
  setCaptureImageIsInShootMode(false, setPhotoSeriesStoreState);
  setCaptureImageImageResponse(null, setPhotoSeriesStoreState);

  onTakePhoto({
    ...props,
    image: photo,
    onActionAfterImageUploadAjax(imageId: string | null, verificationErrors: VerificationError[] | null) {
      props.onActionAfterImageUploadAjax?.(imageId, verificationErrors);
    },
  });
};

const handleSaveImageComment = (imageId: string | null, comment: string, setPhotoSeriesStoreState: SetPhotoSeriesStoreState) => {
  if (imageId && comment.length > 0) {
    makePost('comments', { imageId, text: comment, origin: 2 }).subscribe();
  }

  setImageComment('', setPhotoSeriesStoreState);
};

export {
  setCaptureImageIsLoading,
  setCaptureImageGenericError,
  setCaptureImageBottomText,
  setCaptureImageHeadingText,
  setCaptureImageImageResponse,
  setCaptureImageIsPhotoConfirmed,
  setCaptureImageIsInShootMode,
  setCaptureImageIsInDamageLabellingMode,
  setCaptureImageDamageLabellingInstructionShown,
  setCaptureImageImageTypeTranslationKeys,
  setCaptureImageImageSubTypeTranslationKeys,
  setCaptureImageCameraPhoto,
  setCaptureImageCameraSettings,
  setCaptureImageIsGdprAccepted,
  handleCameraButtonClick,
  handleRetakePhoto,
  setCaptureImageIsVinEntered,
  setCaptureImageAddMoreImagesTotalImagesCaptured,
  setCaptureImageAdditionalImageIndex,
  setCaptureImageItsAddMoreImages,
  setCaptureImageCurrentErrorCount,
  setConfirmNoDamages,
  handleNativeCameraPhotoTaken,
  setCaptureImageNativeCameraPhoto,
  uploadNativeCameraPhoto,
  setCleanCapturedImageObjectUrl,
  RelatedObjectType,
  setImageComment,
  handleSaveImageComment
};

export type { HandleCameraButtonClick };
