/* eslint-disable no-unused-vars */
import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { EMPTY } from 'rxjs';
import StackTrace from 'stacktrace-js';

import { history, Paths } from 'routes';
import { postErrorLog } from 'services';

import { logout } from 'redux/root.actions';

export interface CustomError extends Error {
  __proto__: Record<string, any>;
  [key: string]: any;
}

export const onGlobalError = (message: string, file: string, line: string, column: string, errorObject: CustomError): void => {
  logError(message, file, line, column, errorObject);
  if (errorObject instanceof AjaxError) {
    onAjaxError(errorObject as AjaxError);
  } else {
    handleTechnicalError(message, errorObject);
  }
};

export const submitErrorLog = (
  errorMessage: string,
  errorObject: CustomError | null,
  onSuccess?: (value: AjaxResponse<unknown>) => void,
  onError?: (error: any) => void
) => {
  let message = `${errorMessage} \nUser Agent: ${navigator.userAgent}`;
  if (errorObject) {
    getErrorStack(errorObject).then((stacktrace) => postErrorLog(message, stacktrace)
      .subscribe({
        next: onSuccess,
        error: onError,
      }));
  } else {
    postErrorLog(message, '')
      .subscribe({
        next: onSuccess,
        error: onError,
      });
  }
};

export const handleTechnicalError = (errorMessage: string, errorObject: CustomError) => {
  if (process.env.REACT_APP_ENV === 'development') return;

  const afterPost = () => {
    const sciptError = 'script error'; // Temporary: Ignore "Script error"
    if (errorMessage.toLowerCase().includes(sciptError)) return EMPTY;

    // Stands AdBlocker issue: https://github.com/shadcn-ui/ui/issues/2837
    const googletagError = 'googletag'; // Temporary: Ignore "googletag" error
    if (errorMessage.toLowerCase().includes(googletagError)) return EMPTY;

    showErrorPage();
    return EMPTY;
  };

  submitErrorLog(errorMessage, errorObject, afterPost, afterPost);
};

export const stringifyError = (err: CustomError, filter: any, space: string): string => {
  const plainObject: Record<string, any> = {};

  let propertyNames = Object.getOwnPropertyNames(err);

  if (!propertyNames || propertyNames.length === 0) {
    propertyNames = Object.getOwnPropertyNames(err.__proto__);
  }

  propertyNames.forEach((key: string) => {
    plainObject[key] = err[key];
  });

  return JSON.stringify(plainObject, filter, space);
};

const onAjaxError = (errorObject: AjaxError) => {
  const { status } = errorObject;
  if (status === 401) {
    logout();
    window.location.reload();
  } else {
    showErrorPage();
  }
};

const getErrorStack = (errorObject: CustomError): Promise<any> => {
  if (!errorObject || !errorObject.stack) return Promise.resolve(null);

  return StackTrace.fromError(errorObject).then((stackframes) => stackframes.map((sf) => sf.toString()).join('\n'));
};

const logError = (message: string, file: string, line: string, column: string, errorObject: CustomError): void => {
  getErrorStack(errorObject).then((stack) => {
    const errorTxt = [
      '\n\nOops, something went wrong.',
      '\nMessage:\n',
      message,
      '\nError object:\n',
      errorObject,
      '\nFile:\n',
      file,
      '\nLine:\n',
      line,
      '\nColumn:\n',
      column,
      '\nError stack:\n',
      stack,
    ];

    console.log(errorTxt);
  });
};

const showErrorPage = (): void => {
  history.push(Paths.applicationError);
};
