/* eslint-disable wrap-iife */
import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { PhotoSeries } from 'redux/photoseries/photoseries';
import { usePhotoSeriesStore } from '../../../store/root.hook';
import { getPhotoSeries } from 'redux/photoseries/photoseries.selectors';
import { GenericError, VerificationError } from '../../../store/root';
import { ErrorModal, ErrorModalProps } from '../../../containers/error-modal/error-modal.component';
import { ImageTypeSettings, IMAGE_VERIFICATION_ERROR_TYPE } from 'redux/workflows/workflows';

type Props = {
  currentImageType: number;
  genericError: GenericError | null;
  shownVerificationErrorModal: boolean;
  noVinError: VerificationError | null | undefined;
  onContinue?: React.MouseEventHandler<HTMLButtonElement>;
  onRetake: React.MouseEventHandler<HTMLButtonElement>;
};

export function AugmentedModal(props: Props) {
  const {
    currentImageType,
    noVinError,
    genericError,
    shownVerificationErrorModal,
    onContinue,
    onRetake
  } = props;

  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);
  const {
    captureImage: { imageResponse, currentErrorCount },
    camera: { mode: cameraMode }
  } = usePhotoSeriesStore();

  const { enabledVerificationErrors } = _.find(photoSeries.imageTypeSettings, ({ imageType }) => imageType === currentImageType) as ImageTypeSettings;
  const blockerErrors = enabledVerificationErrors?.reduce((acc: number[], curr) => {
    if (curr?.errorType === IMAGE_VERIFICATION_ERROR_TYPE.BLOCKER) acc.push(curr.error);

    return acc;
  }, []);

  function mustRetakePhoto() {
    if (blockerErrors?.length === 0) return currentErrorCount < 2;

    const verificationErrors = imageResponse?.verificationErrors || [];

    for (let index = 0; index < verificationErrors!.length; index++) {
      const error = verificationErrors && verificationErrors[index].error;

      if (blockerErrors!.indexOf(error!) > -1) return true;
    }

    return currentErrorCount < 2;
  }

  const _mustRetakePhoto = mustRetakePhoto();
  const verificationErrorModalProps = (function deriveProps() {
    const props: ErrorModalProps = {
      shownModal: shownVerificationErrorModal,
      isBlocker: !!blockerErrors?.length,
      vehicle: imageResponse?.vehicle!,
      errors: imageResponse?.verificationErrors!,
      isVinError: Boolean(noVinError),
      showContinueButton: !_mustRetakePhoto,
      enabledVerificationErrors,
      onContinue,
      onRetake,
      cameraMode
    };

    return props;
  })();

  return (
    <>
      <ErrorModal
        shownModal={!!genericError}
        onRetake={() => {
          genericError?.onAction();
        }}
        errors={genericError?.errors!}
        cameraMode={cameraMode}
      />
      <ErrorModal {...verificationErrorModalProps} />
    </>
  );
}
