const apiRoutes = {
  acceptGdpr: 'capture/{0}/terms/acceptGdpr',
  checkGdpr: 'capture/{0}/terms/isGdprAccepted',
};

const imageVerificationErrors = {
  lowQuality: 1,
  vehicleNotInFrame: 2,
  noRegistrationNo: 3,
  registrationNoMismatch: 4,
  colorMismatch: 5,
  noVin: 6,
  vinMismatch: 7,
  vehicleTooFar: 9,
  vehicleNotInTheCenter: 10,
  windshieldNotInFrame: 12,
  wrongPose: 13,
  dirty: 14,
  snowy: 15,
  glared: 16,
  rainy: 17,
  overexposed: 19
};

const genericErrorNumberDenotation = {
  genericError: 1000,
};

const browsers = {
  Chrome: 'Chrome',
  Safari: 'Safari',
  FireFox: 'Firefox',
  Edge: 'Edge',
};

const operatingSystems = {
  AppleIOS: 'iOS',
  Android: 'Android OS',
  BlackBerry: 'BlackBerry OS',
  WindowsMobile: 'Windows Mobile',
};

const genericErrors = {
  lowResolution: 'camera-low-res',
  gdprNotAccepted: 'gdpr-required',
  serverError: 'server-error',
  timeoutError: 'timeout-error',
  cameraNotReadable: 'camera-not-readable'
};

const termsAndConditionsLink = 'https://drivex.ee/{0}/terms_of_use.pdf';

export {
  apiRoutes,
  genericErrors,
  operatingSystems,
  termsAndConditionsLink,
  imageVerificationErrors,
  browsers,
  genericErrorNumberDenotation,
};
