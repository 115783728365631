import React from 'react';
import styled from 'styled-components';

import { isAppleIOS } from 'utils';

const PortraitLockWrapper = styled.div<{ isIOS: boolean }>`
  @media (max-aspect-ratio: 13/9) {
    max-height: var(--view-height);
    min-height: var(--view-height);
    min-width: 100vw;
    max-width: 100vw;

    & .wrapper {
      min-height: var(--view-height);
      max-height: var(--view-height);
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }

  @media screen and (min-aspect-ratio: 13/9) {
    transform: rotate(-90deg);
    transform-origin: left top;
    min-height: 100vw;
    max-height: 100vw;
    min-width: var(--view-height);
    max-width: var(--view-height);
    top: var(--view-height);
    left: 0;
    position: absolute;
    margin-top: var(--page-margin-top);

    & .wrapper {
      min-height: 100vw;
      max-height: 100vw;

      & .landscape {
        min-height: 70vw;
        max-height: 70vw;
      }
    }
  }
`;

type PortraitLockPropTypes = {
  children: React.ReactNode;
};

export const PortraitLock: React.FC<PortraitLockPropTypes> = ({ children }) => {
  const isIOS = isAppleIOS();

  return <PortraitLockWrapper isIOS={isIOS}>{children}</PortraitLockWrapper>;
};
