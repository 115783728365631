import React, { useEffect } from 'react';

import { onGlobalError } from 'utils/error-handler.util';

import { AccessUrlWrapper } from './containers/accessurl-wrapper/accessurl-wrapper';
import { ErrorBoundary } from './containers/error-boundary/error-boundary.component';
import { ValidationWrapper } from './containers/validation-wrapper/validation-wrapper.component';

type ErrorEngineProps = {
  children: React.ReactElement | null;
};

const onGlobalErrorListener = ({ message, filename, lineno, colno, error }: ErrorEvent) => {
  onGlobalError(message, filename, lineno.toString(), colno.toString(), error);
};

export function ErrorEngine(props: ErrorEngineProps) {
  useEffect(() => {
    window.addEventListener('error', onGlobalErrorListener);

    return () => {
      window.removeEventListener('error', onGlobalErrorListener);
    };
  });

  return (
    <ErrorBoundary>
      <AccessUrlWrapper>
        <ValidationWrapper>{props.children}</ValidationWrapper>
      </AccessUrlWrapper>
    </ErrorBoundary>
  );
}
