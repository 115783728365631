import { Translate } from 'react-localize-redux';
import { BodyPart, DamageType, ICustomDamageType } from 'redux/damages/damages';
import styled from 'styled-components';

import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

import { EXTERIOR_SUB_TYPES } from 'redux/workflows/workflows';

const { damage_labelling: { body_part, damage_type } } = TRANSLATIONS_VALUES_KEYS;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  touch-action: pan-y;
`;

const CenterContainer = styled.div`
  max-height: 60%;
  width: 50%;
  overflow-y: scroll;
  background: #FFF;
  border-radius: 1rem;
  touch-action: pan-y;
`;

const Select = styled.div`
  font-size: 1rem;
  line-height: 1rem;
  padding: 1rem 0;
  text-align: center;
  border-bottom: 2px solid rgb(240, 240, 240);
  color: rgb(34, 34, 34);
  font-weight: 500;

  &:hover {
    background: rgb(240, 240, 240);
  }
`;

const AllDamageTypes: DamageType[] = [
  DamageType.Chip, DamageType.Crack, DamageType.Dent, DamageType.LampBroken, DamageType.Misaligned, DamageType.MissingPart,
  DamageType.OtherDamage, DamageType.PaintDamage, DamageType.Rust, DamageType.Scrape, DamageType.Scratch
];

const GlassDamageTypes: DamageType[] = [
  DamageType.Chip, DamageType.Crack, DamageType.OtherDamage
];

const PanelDamageTypes: DamageType[] = [
  DamageType.Dent, DamageType.Misaligned, DamageType.MissingPart, DamageType.OtherDamage, DamageType.PaintDamage,
  DamageType.Rust, DamageType.Scrape, DamageType.Scratch
];

const WheelDamageTypes: DamageType[] = [
  DamageType.Dent, DamageType.MissingPart, DamageType.OtherDamage, DamageType.PaintDamage,
  DamageType.Rust, DamageType.Scrape, DamageType.Scratch
];

const BodyPartSelection : BodyPart[] = [
  BodyPart.BackDoor, BodyPart.Body, BodyPart.DoorGlass, BodyPart.Fender, BodyPart.FrontBumper, BodyPart.FrontDoor, BodyPart.Grille,
  BodyPart.Hood, BodyPart.LicensePlate, BodyPart.Light, BodyPart.Mirror, BodyPart.RearBumper, BodyPart.SideSkirt, BodyPart.TailgateGlass,
  BodyPart.Trunk, BodyPart.Wheel, BodyPart.Windshield, BodyPart.Unknown
];

const DamageTypeSelection : { [key in BodyPart]: DamageType[] } = {
  [BodyPart.BackDoor]: PanelDamageTypes,
  [BodyPart.Body]: PanelDamageTypes,
  [BodyPart.DoorGlass]: GlassDamageTypes,
  [BodyPart.Fender]: PanelDamageTypes,
  [BodyPart.FrontBumper]: PanelDamageTypes,
  [BodyPart.FrontDoor]: PanelDamageTypes,
  [BodyPart.Grille]: PanelDamageTypes,
  [BodyPart.Hood]: PanelDamageTypes,
  [BodyPart.LicensePlate]: PanelDamageTypes,
  [BodyPart.Light]: GlassDamageTypes,
  [BodyPart.Mirror]: PanelDamageTypes,
  [BodyPart.RearBumper]: PanelDamageTypes,
  [BodyPart.SideSkirt]: PanelDamageTypes,
  [BodyPart.TailgateGlass]: GlassDamageTypes,
  [BodyPart.Trunk]: PanelDamageTypes,
  [BodyPart.Wheel]: WheelDamageTypes,
  [BodyPart.Windshield]: GlassDamageTypes,
  [BodyPart.Unknown]: AllDamageTypes
};

const FrontBodyParts = [
  BodyPart.FrontBumper,
  BodyPart.Grille,
  BodyPart.Hood,
  BodyPart.Windshield,
  BodyPart.Fender,
  BodyPart.Mirror,
  BodyPart.LicensePlate,
  BodyPart.Light,
];

const FrontDiagonalBodyParts = [
  ...FrontBodyParts,
  BodyPart.FrontDoor,
  BodyPart.DoorGlass,
  BodyPart.Wheel,
  BodyPart.SideSkirt,
  BodyPart.Body,
  BodyPart.Unknown,
];

const BackBodyParts = [
  BodyPart.TailgateGlass,
  BodyPart.Trunk,
  BodyPart.RearBumper,
  BodyPart.LicensePlate,
  BodyPart.Light,
];

const BackDiagonalBodyParts = [
  ...BackBodyParts,
  BodyPart.BackDoor,
  BodyPart.DoorGlass,
  BodyPart.Wheel,
  BodyPart.SideSkirt,
  BodyPart.Body,
  BodyPart.Unknown,
];

const SideBodyParts = [
  BodyPart.Fender,
  BodyPart.BackDoor,
  BodyPart.FrontDoor,
  BodyPart.Mirror,
  BodyPart.DoorGlass,
  BodyPart.FrontBumper,
  BodyPart.RearBumper,
  BodyPart.Light,
  BodyPart.Wheel,
  BodyPart.SideSkirt,
  BodyPart.Body,
  BodyPart.Unknown,
];

const BodyPartSelectionBySubType : { [key: number]: BodyPart[] } = {
  [EXTERIOR_SUB_TYPES.FRONT]: [
    ...FrontBodyParts,
    BodyPart.Unknown,
  ],
  [EXTERIOR_SUB_TYPES.FRONT_LEFT]: FrontDiagonalBodyParts,
  [EXTERIOR_SUB_TYPES.LEFT]: SideBodyParts,
  [EXTERIOR_SUB_TYPES.BACK_LEFT]: BackDiagonalBodyParts,
  [EXTERIOR_SUB_TYPES.BACK]: [
    ...BackBodyParts,
    BodyPart.Unknown,
  ],
  [EXTERIOR_SUB_TYPES.BACK_RIGHT]: BackDiagonalBodyParts,
  [EXTERIOR_SUB_TYPES.RIGHT]: SideBodyParts,
  [EXTERIOR_SUB_TYPES.FRONT_RIGHT]: FrontDiagonalBodyParts,
};

interface IBodyPartModalProps {
  onBodyPartSelected: (selectedBodyPart: BodyPart) => void;
  imageSubType: number,
}

const getBodyParts = (imageSubType: number) => Object.values(EXTERIOR_SUB_TYPES).includes(imageSubType)
  ? BodyPartSelectionBySubType[imageSubType]
  : BodyPartSelection;

export const BodyPartModal = ({ imageSubType, onBodyPartSelected }: IBodyPartModalProps) => (
  <Translate>
    {({ translate }) => (
      <Wrapper>
        <CenterContainer>
          {getBodyParts(imageSubType)
            .map((bodyPart: BodyPart) => (
              <Select
                data-testid={`body-part-${bodyPart.toString()}`}
                key={`body-part-${bodyPart.toString()}`}
                role="button"
                onClick={() => onBodyPartSelected(bodyPart)}
              >
                {translate(body_part[BodyPart[bodyPart].toLowerCase() as keyof typeof body_part])}
              </Select>
            ))}
        </CenterContainer>
      </Wrapper>
    )}
  </Translate>
);

interface DamageTypeModalProps {
  onDamageTypeSelected: (selectedDamageType: DamageType, customDamageTypeId?: string) => void;
  bodyPart: BodyPart | undefined;
  customDamageTypes: ICustomDamageType[],
}

export const DamageTypeModal = ({ customDamageTypes, onDamageTypeSelected, bodyPart }: DamageTypeModalProps) => ((
  <Translate>
    {({ translate }) => (
      <Wrapper>
        <CenterContainer>
          {
            customDamageTypes.length === 0
              ? (
                bodyPart && DamageTypeSelection[bodyPart].map((damageType: DamageType) => (
                  <Select
                    data-testid={`damage-type-${damageType.toString()}`}
                    key={`damage-type-${damageType.toString()}`}
                    role="button"
                    onClick={() => onDamageTypeSelected(damageType)}
                  >
                    {translate(damage_type[DamageType[damageType].toLowerCase() as keyof typeof damage_type])}
                  </Select>
                ))
              )
              : (
                customDamageTypes.map((customDamageType: ICustomDamageType) => (
                  <Select
                    data-testid={`damage-type-${customDamageType.id}`}
                    key={`damage-type-${customDamageType.id}`}
                    role="button"
                    onClick={() => onDamageTypeSelected(DamageType.OtherDamage, customDamageType.id)}
                  >
                    {customDamageType.name}
                  </Select>
                ))
              )
          }
        </CenterContainer>
      </Wrapper>
    )}
  </Translate>
));
