import React, { useEffect, useState } from 'react';
import { makeGet, makePatch } from 'services';
import { AjaxResponse } from 'rxjs/ajax';
import styled from 'styled-components';
import { ReactComponent as SadMonitorIcn } from 'assets/svgs/icn-sad-monitor.svg';
import { isAppleIOS } from 'utils';
import { Translate } from 'react-localize-redux';
import { ILanguage, TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';
import { ValidationWrapperLayout } from '../validation-wrapper.component';
import CopyLink from '../copy-link/copy-link';
import { Button } from 'components/partials';
import { useTheme } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getPhotoSeries } from 'redux/photoseries/photoseries.selectors';
import { PhotoSeries } from 'redux/photoseries/photoseries';
import { selectSelectedLanguage, selectUnsupportedBrowser } from 'redux/root.selectors';
import { storeUnsupportedBrowser, storeUseNativeCamera } from 'redux/root.actions';
import OrderIcon from '../order-icon/order-icon';
import SupportedBrowsers from '../supported-browsers/supported-browsers';
import { IFaq, getFaqLink } from 'utils/faq.util';

const { validation_wrapper } = TRANSLATIONS_VALUES_KEYS;

interface BlacklistedUserAgents {
  phrase: string;
  operatingSystem?: OperatingSystem | null;
}

enum OperatingSystem {
  Android = 0,
  Ios = 1
}

const Title = styled.div`
  font-size: 1rem;
  text-align: center;
  width: 14rem;
  display: flex;
  flex-direction: column;
  span {
    font-size: 1rem;
  }
`;

const StepWrapper = styled.div`
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const isUserAgentBlacklisted = (blacklistedUserAgents: BlacklistedUserAgents[]): boolean => {
  const userAgent = navigator.userAgent;
  const operatingSystem = isAppleIOS()
    ? OperatingSystem.Ios
    : OperatingSystem.Android;

  const phrases = blacklistedUserAgents
    .filter((blacklistedUserAgent) => blacklistedUserAgent.operatingSystem === operatingSystem || blacklistedUserAgent.operatingSystem === null)
    .map((blacklistedUserAgent) => blacklistedUserAgent.phrase);

  return phrases.some((phrase) => userAgent.indexOf(phrase) >= 0);
};

const faq: IFaq = {
  et: {
    android: 'https://drivex.notion.site/Juhised-Android-telefonidele-c48024e1330d429b84115329ec15b8d6#d4207e3088a34caaa0b6b8d26a823772',
    ios: 'https://drivex.notion.site/Juhised-iOS-telefonidele-b2f7ed9b63624cb0b7a798283d5f4d6a#ed9ef3c0636e4109bc2b22b111aa31c5',
    default: 'https://drivex.notion.site/Korduma-kippuvad-k-simused-KKK-Eesti-58802134dee4457cbc7121fe520ae814'
  },
  en: {
    android: 'https://drivex.notion.site/DriveX-SmartScan-FAQ-for-Android-135d711a5c2f4038826d93d31b8d7e33#fe233d3815c6449492ccc693ff8fe61d',
    ios: 'https://drivex.notion.site/DriveX-SmartScan-FAQ-for-iOS-9f363e02a1254e81bcdb01ae1f3e91f8#8d4b8dbacfdd4802b36a6dbf8ac37127',
    default: 'https://drivex.notion.site/Frequently-Asked-Questions-FAQ-English-f52c39876d0f40b785dddba7a8f0a717'
  }
};

interface UnsupportedUserAgentTypeProps {
  children: React.ReactElement | null;
}

const UnsupportedUserAgent: React.FC<UnsupportedUserAgentTypeProps> = ({ children }) => {
  const { styles } = useTheme();
  const photoSeries: PhotoSeries = useSelector(getPhotoSeries);

  const [isLoadingBlacklistedUserAgents, setIsLoadingBlacklistedUserAgents] = useState<boolean>(true);
  const [continueAnyways, setContinueAnyways] = useState<boolean>(false);
  const unsupportedBrowser = useSelector(selectUnsupportedBrowser);
  const selectedLanguage: ILanguage = useSelector(selectSelectedLanguage);

  const dispatch = useDispatch();

  useEffect(() => {
    makeGet('blacklistedUserAgents').subscribe(
      ({ response }: AjaxResponse<any>) => {
        setIsLoadingBlacklistedUserAgents(false);
        const isBlacklisted = isUserAgentBlacklisted(response);
        dispatch(storeUnsupportedBrowser(isBlacklisted));
        dispatch(storeUseNativeCamera(isBlacklisted));
      }
    );
  }, [dispatch]);

  useEffect(() => {
    if (unsupportedBrowser && photoSeries) {
      makePatch(`photoSeries/${photoSeries.id}`, { unsupportedBrowser: true }).subscribe();
    }
  }, [unsupportedBrowser, photoSeries]);

  if (!unsupportedBrowser || continueAnyways) return children;

  return (
    <ValidationWrapperLayout
      validationWrapperIcon={<SadMonitorIcn />}
      headerTitle={
        <StepWrapper>
          <Title>
            <Translate id={validation_wrapper.unsupportedBrowser_header_text} />
            <span>...</span>
          </Title>
        </StepWrapper>
      }
      content={
        <>
          <StepWrapper>
            <OrderIcon orderNo={1} />
            <CopyLink />
          </StepWrapper>
          <StepWrapper>
            <OrderIcon orderNo={2} />
            <SupportedBrowsers />
          </StepWrapper>
          <Button
            testId="continueAnywaysBtn"
            bg="white"
            color={styles.colorPrimaryButtonBackground}
            onClick={() => setContinueAnyways(true)}
          >
            <Translate id={validation_wrapper.continue_anyways_btn} />
          </Button>
        </>
      }
      footer={{
        questionText: <Translate id={validation_wrapper.question_text} />,
        questionLink: getFaqLink(faq, selectedLanguage),
        questionLinkText: <Translate id={validation_wrapper.unsupportedBrowserLink_text} />
      }}
      isLoading={isLoadingBlacklistedUserAgents}
    />
  );
};

export default UnsupportedUserAgent;
