import { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as IconCopy } from 'assets/svgs/icn-copy-icon.svg';
import { ReactComponent as IconCheck } from 'assets/svgs/icn-checkbox-checked.svg';
import { Translate } from 'react-localize-redux';
import { useTheme } from 'hooks';
import { TRANSLATIONS_VALUES_KEYS } from 'redux/internationalization/internationalization';

const { validation_wrapper } = TRANSLATIONS_VALUES_KEYS;

const CopyLinkWrapper = styled.div<{ color: string }>`

  & .svg {
    height: 1rem;
    padding-right: 0.313rem;
  }

  & .row-two__row1 {
    text-align: center;
    padding-top: 0.625rem;
    width: 13rem;
    font-size: 0.813rem;
    font-weight: 600;
  }

  & .row-two__row2 {
    margin-top: 0.375rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007BFF;
    font-size: 0.75rem;

    & .icn-copied {
      fill: ${({ color }) => color};
    }
  }
`;

const CopyLink = () => {
  const { styles } = useTheme();
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyLink = () => {
    const node = document.createElement('textarea');

    document.body.appendChild(node);
    node.value = window.location.href;
    node.select();
    document.execCommand('copy');
    document.body.removeChild(node);

    setIsLinkCopied(true);
  };

  return (
    <CopyLinkWrapper color={isLinkCopied ? styles.colorSuccess : styles.colorParagraph} role="button" tabIndex={0} onClick={() => copyLink()}>
      <div className="row-two__row1">
        <Translate id={validation_wrapper.copy_link_instruction_text} />
      </div>
      <div className="row-two__row2">
        {isLinkCopied ? <IconCheck className="icn-copied svg" /> : <IconCopy className="svg" />}
        {
          isLinkCopied
            ? <Translate id={validation_wrapper.linkCopied_text} />
            : <Translate id={validation_wrapper.copy_link_text} />
        }
      </div>
    </CopyLinkWrapper>
  );
};

export default CopyLink;
