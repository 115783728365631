/* eslint-disable no-undef */
import { __local__ } from 'utils/const.util';
import { isMinimumConstraints, getBlob, rotateBlobImageLeft } from './helper.util';
import { SUPPORTED_FACING_MODES, FACING_MODES, IMAGE_TYPES, MINIMUM_CONSTRAINTS } from './media-services-constants.util';

// inspired by https://unpkg.com/browse/scandit-sdk@4.6.1/src/lib/cameraAccess.ts
const backCameraKeywords = [
  'rear',
  'back',
  'rück',
  'arrière',
  'trasera',
  'trás',
  'traseira',
  'posteriore',
  '后面',
  '後面',
  '背面',
  '后置', // alternative
  '後置', // alternative
  '背置', // alternative
  'задней',
  'الخلفية',
  '후',
  'arka',
  'achterzijde',
  'หลัง',
  'baksidan',
  'bagside',
  'sau',
  'bak',
  'tylny',
  'takakamera',
  'belakang',
  'אחורית',
  'πίσω',
  'spate',
  'hátsó',
  'zadní',
  'darrere',
  'zadná',
  'задня',
  'stražnja',
  'belakang',
  'बैक',
];

export type IVideo = {
  facingMode: ConstrainDOMString;
  deviceId: string;
  width: Sizing;
  height: Sizing;
  advanced: Object;
  aspectRatio: Sizing;
};

export type IDealConstraints = {
  audio: boolean;
  video: IVideo;
};

type Sizing = {
  min?: number;
  ideal?: number;
  max?: number;
  exact?: number;
};

export type IDealResolution = {
  width: Sizing;
  height?: Sizing;
};

export type Config = {
  sizeFactor: string;
  imageType: string;
  imageCompression: number;
  isImageMirror: boolean;
};

export type WindowUrl = {
  // eslint-disable-next-line no-unused-vars
  createObjectURL(object: any): string;
  [key: string]: any;
};

const ASPECT_RATIO_4_BY_3 = 4 / 3;

class MediaServices {
  static getImage(videoElement: HTMLVideoElement, config: Config) {
    const imagePromise = new Promise<Blob | null>((res) => {
      videoElement.onpause = () => {
        const { imageType, imageCompression } = config;
        const { videoWidth, videoHeight } = videoElement;

        const canvas = document.createElement('canvas');
        canvas.width = videoWidth;
        canvas.height = videoHeight;

        const context = canvas.getContext('2d');

        context!.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        // Get blob from canvas
        getBlob(canvas, imageType, imageCompression)
          .then((blob) => {
            const portraitQuery = window.matchMedia('screen and (max-aspect-ratio: 13/9)');

            // Rotate image if necessary
            if (portraitQuery.matches) {
              return rotateBlobImageLeft(blob)
                .then(({ rotatedBlob }) => res(rotatedBlob));
            }

            return res(blob);
          });
      };
    });

    videoElement.pause();

    return imagePromise.then((blob) => ({ forUpload: blob }));
  }

  static getWindowURL() {
    const _window: any = window;
    const windowURL: WindowUrl = _window.URL || _window.webkitURL || _window.mozURL || _window.msURL;

    return windowURL;
  }

  /*
    Inspiration : https://github.com/jhuckaby/webcamjs/blob/master/webcam.js
    */
  static getNavigatorMediaDevices() {
    const _navigator: any = window.navigator;

    let NMDevice = null;
    const isNewAPI = !!(_navigator.mediaDevices && _navigator.mediaDevices.getUserMedia);
    const isOldAPI = !!(_navigator.mozGetUserMedia || _navigator.webkitGetUserMedia);

    if (isNewAPI) {
      NMDevice = navigator.mediaDevices;
    } else if (isOldAPI) {
      const NMDeviceOld = _navigator.mozGetUserMedia || _navigator.webkitGetUserMedia;
      // Setup getUserMedia, with polyfill for older browsers
      // Adapted from: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia

      const polyfillGetUserMedia = {
        getUserMedia<T>(constraint: T) {
          return new Promise((resolve, reject) => {
            NMDeviceOld.call(_navigator, constraint, resolve, reject);
          });
        },
      };

      // Overwrite getUserMedia() with the polyfill
      NMDevice = Object.assign(NMDeviceOld, polyfillGetUserMedia);
    }

    // If is no navigator.mediaDevices || navigator.mozGetUserMedia || navigator.webkitGetUserMedia
    // then is not supported so return null
    return NMDevice;
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API/Constraints
  static isSupportedFacingMode() {
    // navigator.mediaDevices
    return MediaServices.getNavigatorMediaDevices().getSupportedConstraints().facingMode;
  }

  static getIdealConstraints(idealFacingMode: string, idealResolution?: IDealResolution, mainCamera?: IVideo) {
    // default idealConstraints
    const idealConstraints: IDealConstraints = {
      audio: false,
      video: {} as IVideo,
    };

    if (isMinimumConstraints(idealFacingMode, idealResolution)) {
      return MINIMUM_CONSTRAINTS;
    }

    const supports = navigator.mediaDevices.getSupportedConstraints();

    if (!supports.width || !supports.height || !supports.facingMode) {
      console.error('Constraint width height or facingMode not supported!');
      return MINIMUM_CONSTRAINTS;
    }

    if (supports.aspectRatio) {
      idealConstraints.video.aspectRatio = {
        min: 1,
        ideal: ASPECT_RATIO_4_BY_3,
      };
    } else if (idealResolution && idealResolution.width && idealResolution.width.min && idealResolution.width.max) {
      // NOTE: Some browsers like Firefox does not support aspectRatio
      //       In order to force it we calculate height by provided width
      idealConstraints.video.height = {
        min: idealResolution.width.min / ASPECT_RATIO_4_BY_3,
        ideal: idealResolution.width.max / ASPECT_RATIO_4_BY_3,
        max: idealResolution.width.max / ASPECT_RATIO_4_BY_3
      };
    }

    // If is valid facingMode
    if (idealFacingMode && SUPPORTED_FACING_MODES.includes(idealFacingMode)) {
      idealConstraints.video.facingMode = { [__local__ ? 'ideal' : 'exact']: idealFacingMode };
    }

    if (idealResolution && idealResolution.width) {
      idealConstraints.video.width = idealResolution.width;
    }

    if (idealResolution && idealResolution.height) {
      idealConstraints.video.height = idealResolution.height;
    }

    // Select correct camera on devices with multiple back-facing cameras
    if (mainCamera && mainCamera.deviceId) {
      idealConstraints.video.deviceId = mainCamera.deviceId;
    }

    return idealConstraints;
  }

  static getMainCamera() {
    return new Promise((resolve) => {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const backCameras = devices.filter((device) => device.kind === 'videoinput' && this.isBackCameraLabel(device.label));

        if (backCameras.length > 0) {
          let cameraPool = backCameras;

          // sort camera pool by label
          cameraPool = cameraPool.sort((camera1, camera2) => camera1.label.localeCompare(camera2.label));

          // Check if cameras are labeled with resolution information, take the higher-resolution one in that case
          // Otherwise pick the first camera
          let selectedCameraIndex = 0;

          const cameraResolutions = cameraPool.map((camera) => {
            const regExp = RegExp(/\b([0-9]+)MP?\b/, 'i');
            const match = regExp.exec(camera.label);
            if (match !== null) {
              return parseInt(match[1], 10);
            }
            return NaN;
          });

          if (!cameraResolutions.some((cameraResolution) => Number.isNaN(cameraResolution))) {
            selectedCameraIndex = cameraResolutions.lastIndexOf(Math.max(...cameraResolutions));
          }

          resolve(cameraPool[selectedCameraIndex]);
        } else {
          // no cameras available on the device
          resolve(null);
        }
      });
    });
  }

  static isBackCameraLabel(label: string) {
    const lowercaseLabel = label.toLowerCase();

    return backCameraKeywords.some((keyword) => lowercaseLabel.includes(keyword));
  }

  static getMaxResolutionConstraints(idealFacingMode: string, numberOfMaxResolutionTry: number) {
    const constraints = MediaServices.getIdealConstraints(idealFacingMode) as IDealConstraints;
    const { facingMode } = constraints.video as IVideo;

    const VIDEO_ADVANCED_CONSTRANTS = [
      { width: { min: 640 }, ideal: { facingMode } },
      { width: { min: 800 }, ideal: { facingMode } },
      { width: { min: 900 }, ideal: { facingMode } },
      { width: { min: 1024 }, ideal: { facingMode } },
      { width: { min: 1080 }, ideal: { facingMode } },
      { width: { min: 1280 }, ideal: { facingMode } },
      { width: { min: 1920 }, ideal: { facingMode } },
      { width: { min: 2560 }, ideal: { facingMode } },
      { width: { min: 3840 }, ideal: { facingMode } },
    ];

    if (numberOfMaxResolutionTry >= VIDEO_ADVANCED_CONSTRANTS.length) {
      return null;
    }

    // each number of try, we remove the last value of the array (the bigger minim width)
    const advanced = VIDEO_ADVANCED_CONSTRANTS.slice(0, -numberOfMaxResolutionTry);
    constraints.video.advanced = advanced;

    return constraints;
  }

  static get FACING_MODES() {
    return FACING_MODES;
  }

  static get IMAGE_TYPES() {
    return IMAGE_TYPES;
  }
}

export default MediaServices;
